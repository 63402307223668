import { template as template_bce351ab3e9f40f5ae98a8ed2e35a608 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_bce351ab3e9f40f5ae98a8ed2e35a608(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
